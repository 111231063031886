// src/components/PageDetails.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import { Chart, registerables } from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import annotationPlugin from 'chartjs-plugin-annotation';
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import DiffViewerComponent from './DiffViewer';

Chart.register(...registerables, annotationPlugin);

const PageDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
`;

const ModalContent = styled.div`
  position: relative;
  padding: 20px;
`;

const ChartContainer = styled.div`
  width: 85%;
  height: 500px;
  margin: 40px 0;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const PageDetails = ({ pageName: propPageName }) => {
  const { pageName: paramPageName } = useParams();
  const pageName = propPageName || paramPageName;
  const [selectedDate, setSelectedDate] = useState(null);
  const [previousDate, setPreviousDate] = useState(null);
  const [dates, setDates] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    const fetchDates = async () => {
      const result = await axios.get(`/api/pages/${pageName}`);
      setDates(result.data);
    };
    fetchDates();
  }, [pageName]);

  const handleDateSelect = (date, index) => {
    setSelectedDate(date);
    setPreviousDate(index > 0 ? dates[index - 1] : null);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleClick = (event, elements) => {
    if (elements.length) {
      const elementIndex = elements[0].index;
      const date = dates[elementIndex];
      if (date.group_title || date.media_section || date.group_info || date.description) {
        handleDateSelect(date, elementIndex);
      }
    }
  };

  const chartData = {
    labels: dates.map(change => change.date),
    datasets: [
      {
        type: 'line',
        label: 'Page Views',
        data: dates.map(change => change.facebook_analytics?.page_views || 0),
        borderColor: 'blue',
        backgroundColor: 'blue',
        yAxisID: 'y',
      },
      {
        type: 'bar',
        label: 'Purchases',
        data: dates.map(change => change.facebook_analytics?.purchases || 0),
        backgroundColor: 'green',
        yAxisID: 'y1',
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio:false,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date',
        },
      },
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        title: {
          display: true,
          text: 'Page Views',
        },
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        title: {
          display: true,
          text: 'Purchases',
        },
        grid: {
          drawOnChartArea: false,
        },
      },
    },
    plugins: {
      annotation: {
        annotations: dates.map((change, index) => {
          const hasSkoolData = change.group_title || change.media_section || change.group_info || change.description;
          return {
            type: 'point',
            xValue: change.date,
            yValue: Math.max(chartData.datasets[0].data[index], chartData.datasets[1].data[index]) + 5,
            backgroundColor: hasSkoolData ? 'red' : 'transparent',
            borderColor: hasSkoolData ? 'red' : 'transparent',
            borderWidth: 2,
            radius: 5,
            yAdjust:20,
            hoverRadius: 20,
            onClick: hasSkoolData ? () => handleDateSelect(change, index) : null,
            label: {
              display: true,
              content: '',
              position: 'start',
              adjustScaleRange: false,
            },
            drawTime: 'afterDatasetsDraw',
          };
        }),
      },
    },
    onClick: handleClick,
    onHover: (event, elements) => {
      const elementIndex = elements.length ? elements[0].index : null;
      const date = elementIndex !== null ? dates[elementIndex] : null;
      const hasSkoolData = date && (date.group_title || date.media_section || date.group_info || date.description);
      event.native.target.style.cursor = hasSkoolData ? 'pointer' : 'default';
    },
  };

  return (
    <PageDetailsContainer>
      <h1>{pageName} Page Details</h1>
      <ChartContainer>
        <Bar data={chartData} options={chartOptions} />
      </ChartContainer>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Diff Viewer"
        ariaHideApp={false}
      >
        <ModalContent>
          <CloseButton onClick={closeModal}>
            <FaTimes />
          </CloseButton>
          {selectedDate && previousDate !== null && (
            <DiffViewerComponent pageName={pageName} date1={previousDate.date} date2={selectedDate.date} />
          )}
        </ModalContent>
      </Modal>
    </PageDetailsContainer>
  );
};

export default PageDetails;
