// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import PageDetails from './components/PageDetails'; // Import the new component

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const PageListContainer = styled.div`
  width: 80%;
  margin: 20px 0;
`;

const PageTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;

  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }

  th {
    background-color: #f2f2f2;
  }
`;

const Button = styled.button`
  padding: 10px 15px;
  margin: 5px;
  background-color: ${props => props.bgcolor || '#007bff'};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.hovercolor || '#0056b3'};
  }
`;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 10px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
`;

const ModalContent = styled.div`
  position: relative;
  padding: 20px;
`;

const App = () => {
  const [selectedPage, setSelectedPage] = useState(null);
  const [pages, setPages] = useState([]);
  const [newPage, setNewPage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    const fetchPages = async () => {
      const result = await axios.get('/api/pages');
      setPages(result.data);
    };
    fetchPages();
  }, []);

  const handlePageSelect = (page) => {
    setSelectedPage(page);
  };

  const handleAddPage = async () => {
    if (newPage) {
      try {
        await axios.post('/api/pages/validate', { pageName: newPage });
        await axios.post('/api/pages', { pageName: newPage });
        setPages([...pages, newPage]);
        setNewPage('');
        setErrorMessage('');
      } catch (error) {
        setErrorMessage('Invalid page slug or page already exists.');
      }
    }
  };

  const handleRemovePage = async (page) => {
    if (window.confirm(`Are you sure you want to remove the page: ${page}?`)) {
      await axios.delete(`/api/pages/${page}`);
      setPages(pages.filter(p => p !== page));
      if (page === selectedPage) {
        setSelectedPage(null);
      }
    }
  };

  return (
    <Router>
      <AppContainer>
        <Routes>
          <Route path="/" element={
            <>
              <h1>Skool About Page Changes</h1>
              <PageListContainer>
                <h2>Tracked Pages</h2>
                <PageTable>
                  <thead>
                    <tr>
                      <th>Page Name</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pages.map(page => (
                      <tr key={page}>
                        <td>
                          <Link to={`/${page}`} style={{ textDecoration: 'none', color: '#007bff' }}>
                            {page}
                          </Link>
                        </td>
                        <td>
                          <Button bgcolor="#dc3545" hovercolor="#c82333" onClick={() => handleRemovePage(page)}>Remove</Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </PageTable>
                <input type="text" value={newPage} onChange={(e) => setNewPage(e.target.value)} placeholder="Add new page" />
                <Button onClick={handleAddPage}>Add Page</Button>
                {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
              </PageListContainer>
              <select onChange={(e) => handlePageSelect(e.target.value)}>
                <option value="">Select a page</option>
                {pages.map(page => (
                  <option key={page} value={page}>{page}</option>
                ))}
              </select>
              {selectedPage && <PageDetails pageName={selectedPage} />}
            </>
          } />
          <Route path="/:pageName" element={<PageDetails />} /> {/* Add this route */}
        </Routes>
      </AppContainer>
    </Router>
  );
};

export default App;
