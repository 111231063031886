// src/components/DiffViewer.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import DiffViewer, { DiffMethod } from 'react-diff-viewer-continued';
import { diffJson } from 'diff';

const DiffContainer = styled.div`
  padding: 20px;
`;

const Section = styled.div`
  margin-bottom: 20px;
`;

const DatesHeader = styled.div`
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
`;

// Helper function to count changes
const countChanges = (oldJson, newJson) => {
  const diff = diffJson(oldJson, newJson);
  let changeCount = 0;
  let isChangeOngoing = false; // To track ongoing changes

  diff.forEach(part => {
    if (part.added || part.removed) {
      if (!isChangeOngoing) {
        changeCount += 1;
        isChangeOngoing = true; // Mark that a change is ongoing
      }
    } else {
      isChangeOngoing = false; // Reset if a non-change part is encountered
    }
  });

  return changeCount;
};

const findPreviousDateWithSkoolData = (changes, currentIndex) => {
  for (let i = currentIndex - 1; i >= 0; i--) {
    const change = changes[i];
    if (change.group_title || change.media_section || change.group_info || change.description) {
      return changes[i];
    }
  }
  return null;
};

const DiffViewerComponent = ({ pageName, date1, date2 }) => {
  const [diffs, setDiffs] = useState(null);
  const [changeCounts, setChangeCounts] = useState({});
  const [previousDate, setPreviousDate] = useState(null);

  useEffect(() => {
    const fetchDiffs = async () => {
      try {
        const result = await axios.get(`/api/pages/${pageName}`);
        const changes = result.data;

        const currentIndex = changes.findIndex(change => change.date === date2);
        const previousChange = findPreviousDateWithSkoolData(changes, currentIndex);

        if (previousChange) {
          const prevDate = previousChange.date;
          setPreviousDate(prevDate);
          const diffsResult = await axios.get(`/api/pages/${pageName}/diff`, {
            params: { date1: prevDate, date2 },
          });
          setDiffs(diffsResult.data);
        } else {
          setDiffs(null); // No previous change to compare
        }
      } catch (error) {
        console.error("Error fetching diffs:", error);
      }
    };

    fetchDiffs();
  }, [pageName, date2]);

  useEffect(() => {
    if (diffs) {
      const counts = {
        group_title: countChanges(diffs.doc1.group_title, diffs.doc2.group_title),
        media_section: countChanges(diffs.doc1.media_section, diffs.doc2.media_section),
        group_info: countChanges(diffs.doc1.group_info, diffs.doc2.group_info),
        description: countChanges(diffs.doc1.description, diffs.doc2.description),
      };
      setChangeCounts(counts);
    }
  }, [diffs]);

  if (!diffs) return <div>Loading...</div>;

  const renderDiff = (section) => (
    <DiffViewer
      oldValue={diffs.doc1[section]}
      newValue={diffs.doc2[section]}
      compareMethod={DiffMethod.JSON}
      splitView={true}
    />
  );

  return (
    <DiffContainer>
      <DatesHeader>
        Comparing changes from {previousDate} to {date2}
      </DatesHeader>
      <Section>
        <h2>Group Title (Changes: {changeCounts.group_title || 0})</h2>
        {renderDiff('group_title')}
      </Section>
      <Section>
        <h2>Media Section (Changes: {changeCounts.media_section || 0})</h2>
        {renderDiff('media_section')}
      </Section>
      <Section>
        <h2>Group Info (Changes: {changeCounts.group_info || 0})</h2>
        {renderDiff('group_info')}
      </Section>
      <Section>
        <h2>Description (Changes: {changeCounts.description || 0})</h2>
        {renderDiff('description')}
      </Section>
    </DiffContainer>
  );
};

export default DiffViewerComponent;
